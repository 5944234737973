import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { SvgIconComponent } from 'angular-svg-icon';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-text-only-read',
  templateUrl: './text-only-read.component.html',
  standalone: true,
  imports: [NgIf, SvgIconComponent, TranslateModule],
})
export class TextOnlyReadComponent {
  @Input() options: any = {};
}
