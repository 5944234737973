import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LanguageService } from '../../language/language.service';
import { UserService } from '../../user/user.service';

@Injectable({
  providedIn: 'root',
})
export class ConsolidatorsService {
  apiUrl: string = environment.api_url;
  vistaTravelUrl: string = environment.vistatravel_url;
  vistaTravelRedirect: string;
  backendUrl: string = environment.api_backend_url;

  constructor(
    private http: HttpClient,
    private userService: UserService,
    private languageService: LanguageService
  ) {}

  private getUrlVistatravel(url: string): string {
    if (
      url.indexOf('/admin/usuarios/listado') > -1 ||
      url.indexOf('agents/list') > -1
    ) {
      this.vistaTravelRedirect = '/app/admin/agencias/ListAgents';
    } else if (
      url.indexOf('/admin/usuario/nuevo') > -1 ||
      url.indexOf('agents/new') > -1
    ) {
      this.vistaTravelRedirect = '/app/admin/agencias/CreateAgent';
    } else if (
      url.indexOf('/admin/perfile/editar') > -1 ||
      url.indexOf('my-profile') > -1
    ) {
      this.vistaTravelRedirect = '/app/admin/agencias/modify';
    } else if (url.indexOf('/admin/perfile/add') > -1) {
      this.vistaTravelRedirect = '/app/saldo';
    } else {
      return this.getUrlGeneric(url);
    }
    return this.vistaTravelUrl + '' + this.vistaTravelRedirect;
  }

  private getUrlGeneric(url: string): string {
    let params = new HttpParams();
    params = params.append('e', this.userService.getCurrentUSer().u.nc!);
    params = params.append('w', this.userService.getCurrentUSer().u.wp!);
    params = params.append('redirect', this.CreateUrlRedirect(url));
    params = params.append('onlybody', '1');

    const idAgente = this.userService.getCurrentUSer().u?.si;

    if (idAgente && idAgente !== null && idAgente !== '') {
      params = params.append('idAgente', idAgente);
    }

    if (
      this.userService.getCurrentUSer().client.tn &&
      this.userService.getCurrentUSer().client.tn === 'vistatravel'
    ) {
      const si = this.userService.getCurrentUSer().u.si ?? '';
      params = params.append('v', si);
    }

    return (
      this.userService.getCurrentUSer()?.client?.aiu + '/auto-login?' + params
    );
  }

  getAutologinUrl(url: string): string {
    if (
      this.userService.isVistatravel() &&
      !this.userService.isVistaTravelAgencyMigrated()
    ) {
      return this.getUrlVistatravel(url);
    }

    return this.getUrlGeneric(url);
  }

  CreateUrlRedirect(url: string): string {
    let transformUrl = url;
    if (this.languageService.getLocaleCode() !== 'es') {
      transformUrl = '/' + this.languageService.getLocaleCode() + url;
    }
    return transformUrl;
  }

  getConsolidatorsList(): Observable<Consolidator[]> {
    let headers = new HttpHeaders();
    headers = headers.append('hideError', 'yes');

    return this.http.get<Consolidator[]>(
      this.backendUrl + '/api/admin/consolidator/list',
      {
        headers,
      }
    );
  }

  getGroupsList(): Observable<Consolidator[]> {
    let headers = new HttpHeaders();
    headers = headers.append('hideError', 'yes');

    return this.http.get<Consolidator[]>(
      this.backendUrl + '/api/admin/group/list',
      {
        headers,
      }
    );
  }

  getConsolidatorData(id: string): Observable<Consolidator> {
    let headers = new HttpHeaders();
    headers = headers.append('hideError', 'yes');

    return this.http.get<Consolidator>(
      this.backendUrl + '/api/admin/consolidator/' + id,
      {
        headers,
      }
    );
  }

  setConsolidatorsData(data): Observable<Consolidator> {
    let headers = new HttpHeaders();
    headers = headers.append('hideError', 'yes');

    return this.http.post<Consolidator>(
      this.backendUrl + '/api/admin/consolidator/update',
      data,
      { headers }
    );
  }

  setConsolidatorsLogos(data): Observable<Consolidator> {
    let headers = new HttpHeaders();
    headers = headers.append('hideError', 'yes');

    return this.http.post<Consolidator>(
      this.backendUrl + '/api/admin/consolidator/upload',
      data,
      { headers }
    );
  }
}

export interface Consolidator {
  active: boolean;
  codPostal: string;
  code: string;
  contact: string;
  corp: string;
  country: string;
  createdAt: TimeObject;
  date: string;
  timezone: string;
  timezone_type: number;
  direccion: string;
  email: string;
  emailBookings: string;
  emailQueues: string;
  emailQueuesBody: string;
  emailSender: string;
  fax: string;
  id: number;
  isProvider: boolean;
  localidad: string;
  logo: string | null;
  logoS3Name: string;
  movil: string;
  name: string;
  phone: string;
  updatedAt: TimeObject;
  consolidatorCss: ConsolidatorCss[];
}

interface TimeObject {
  date: string;
  timezone_type: number;
  timezone: string;
}

export interface ConsolidatorCss {
  buttonColor: string;
  buttonHoverColor: string;
  createdAt: TimeObject;
  id: number;
  primaryColor: string;
  segundaryColor: string;
  thirdColor: string;
  updatedAt: TimeObject;
  favicon: string;
  logoPng: string;
  logoSvg: string;
}
