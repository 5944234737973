// api getUserData response.
// Agency info
import { DeserializableAbstract } from './deserializable.abstact';

export class AgencyModel extends DeserializableAbstract {
  // agency id
  public i: string;
  // agency name
  public n: string;
  // Cantidad de emisiones gratuitas
  public fe: number;
  // agency address
  public a: string;
  // agency email
  public e: string;
  // agency phone
  public p: string;
  // agency country
  public c: string;
  // agecy source id.
  public si?: string;
  // agecy Agency can change service fee.
  public ccsf?: boolean;
  // proveedores con iata
  public pi?: string[];
  // saldo amount
  public s: number;
  // limite credito amount
  public lc: number;
  // código RP de la oficina de la agencia
  public t: Record<string, string[]>;
  // Mostrar modal service fee
  public sfm: boolean;
  // Mostrar proveedor en item
  public sp: boolean;
}
