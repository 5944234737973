<div class="file-upload" [ngClass]="{backGroundChoseIcon:(chooseIcon), backGroundFavicon: typeTemplate === 'favicon'}" >
  <button *ngIf="!chooseIcon" class="button-open" type="button" (click)="openFileDialog()">
    {{ chooseLabel | translate}}
  </button>
  <div *ngIf="chooseIcon && (mode === 'default' || (mode === 'img' && selectedFiles.length === 0 && !hasFilePreviews()))" class="image-button-box">
    <div  class="button-open-span" [ngClass]="{faviconButton: typeTemplate === 'favicon'}"  (click)="openFileDialog()">
      <span *ngIf="typeTemplate !== 'favicon'">{{chooseLabel |translate}}</span>
    </div>
  </div>

  <ul *ngIf="selectedFiles.length > 0" class="p-listbox p-component">
    <li *ngFor="let file of selectedFiles" class="p-listbox-item">
      <div class="file-name" *ngIf="mode === 'default'; else imagenPreviewOptionTemplate">
        <div class="names">
          <p>{{ file.name }}</p>
          <p class="pl-3">{{ getFileSize(file) }}</p>
        </div>
        <div class="accion-delete">
          <button type="button" (click)="removeFile(file)">
            <span class="">x</span>
          </button>
        </div>
      </div>
      <ng-template #imagenPreviewOptionTemplate>
        <div class="file-preview" *ngIf="!fileIntegration">
          <p><b>{{'name_' | translate }}:{{ file.name }}</b></p>
          <div *ngIf="filePreviewUrls[file.name]" class="img-box" (click)="openFileDialog()">
            <img *ngIf="isImage(file) && accept !== 'svg'" [src]="filePreviewUrls[file.name]" alt="Vista previa" />
            <svg-icon *ngIf="isImage(file) && accept === 'svg'" [src]="filePreviewUrls[file.name]" alt="Vista previa"></svg-icon>
            <span>{{'size_' |translate}}:{{ getFileSize(file) }} <button type="button" (click)="removeFile(file)">
              <span class="">x</span>
            </button></span>
          </div>
        </div>
        <div class="file-preview-integration" *ngIf="fileIntegration">
          <div *ngIf="filePreviewUrls[file.name]" class="img-box" [ngClass]="{faviconBox: typeTemplate === 'favicon'}" (click)="openFileDialog()">
            <img *ngIf="isImage(file) && accept !== 'svg'" [src]="filePreviewUrls[file.name]" alt="Vista previa" />
            <svg-icon *ngIf="isImage(file) && accept === 'svg'" [src]="filePreviewUrls[file.name]" alt="Vista previa"></svg-icon>
          </div>
        </div>
      </ng-template>

    </li>
  </ul>
  <div class="p-listbox p-component" >
    <div class="p-listbox-item">
      <div class="file-preview-integration" *ngIf="fileIntegration && hasFilePreviews() && selectedFiles.length === 0">
        <div *ngIf="filePreviewUrls[typeFile]" class="img-box" [ngClass]="{faviconBox: typeTemplate === 'favicon'}" (click)="openFileDialog()">
          <img *ngIf="accept !== 'svg'" [src]="filePreviewUrls[typeFile]" alt="Vista previa" />
          <svg-icon *ngIf="accept === 'svg'" [src]="filePreviewUrls[typeFile]" alt="Vista previa"></svg-icon>
        </div>
      </div>
    </div>
  </div>

  <input type="file" #fileInput (change)="onFileSelected($event)" hidden [accept]="accept" />
</div>
<div *ngIf="errorMessage" class="error-message-box" [ngClass]="{imgError:mode === 'img'}">
  <div>
    <p class="error-message">
      <svg-icon></svg-icon> {{ errorMessage }}
    </p>
  </div>
  <button type="button" (click)="removeFileError()">
    <span class="">x</span>
  </button>
</div>
