import { Injectable } from '@angular/core';
import {
  HttpBackend,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable, of, Subject, Subscription, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError, filter, take, takeUntil } from 'rxjs/operators';
import { JwtService } from '../services/user/jwt.service';
import { UserService } from '../services/user/user.service';
import { ManagementModalService } from 'src/app/core/services/utils/management-modal/management-modal.service';
import { ErrorsService } from '../services/erros/errors.service';
import {
  ModalButtons,
  ModalIcon,
} from 'src/app/modules/application/transport/components/modal-options/modal-options.component';
import { RouterService } from '../services/router/router.service';
import { Location } from '@angular/common';
import { LanguageService } from '../services/language/language.service';
import { StorageService } from '../services/utils/storage.service';

@Injectable()
export class HttpTokenInteceptor implements HttpInterceptor {
  modalButton$: Subscription;
  urlPeticionEnCurso: any = [];
  seconds = 5;
  featureFlags: any[] = [];
  private cancelSubject: Subject<void> = new Subject<void>();

  constructor(
    private jwt: JwtService,
    private userService: UserService,
    private managementModal: ManagementModalService,
    private errorsService: ErrorsService,
    private routerService: RouterService,
    private location: Location,
    private languageService: LanguageService,
    private storageService: StorageService,
    private httpBackend: HttpBackend
  ) {
    this.initFeatureFlags();
  }

  initFeatureFlags(): void {
    Object.keys(localStorage).forEach((localKey) => {
      if (localKey.includes('featureFlag')) {
        this.featureFlags.push({
          key: localKey,
          value: localStorage[localKey],
        });
      }
    });
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let seconds = 0;
    let errorControl = true;
    let interceptorControl = true;
    let dupReq;
    if (req.headers.get('hideError') === 'yes') {
      errorControl = false;
      req = req.clone({ headers: req.headers.delete('hideError') });
    }
    if (req.headers.get('intercept') === 'no') {
      interceptorControl = false;
    }
    // init request.
    let request = req;
    if (req.headers.get('skip')) {
      return this.handleRequest(request, next, errorControl);
    }
    // language
    const lan = localStorage.getItem('lang')
      ? localStorage.getItem('lang')
      : 'es-es';
    let headersConfig: any = {
      lang: `${lan}`,
    };

    // add default Content-type if not specified
    if (!req.headers.get('Content-type')) {
      headersConfig = {
        ...headersConfig,
        'Content-type': 'application/json',
      };
    }
    // add default Accept if not specified
    if (!req.headers.get('Accept')) {
      headersConfig = {
        ...headersConfig,
        Accept: 'application/json',
      };
    }

    // if backend request set Bearer token
    if (req.url.search(environment.api_backend_url) > -1) {
      headersConfig = {
        ...headersConfig,
        ENV: this.userService.getClientName(),
      };

      // si tengo token validar experien
      // En caso de que el token este expeirado, no mandamos la petión a backen ya que nos daria el error 401
      // if (this.jwt.isTokenExpired()) {
      //   return this.handleRequest(
      //     new HttpRequest('OPTIONS', ''),
      //     next,
      //     errorControl
      //   );
      // }
      const token = this.jwt.getToken();

      if (token) {
        const key = 'Authorization';
        headersConfig[key] = `Bearer ${token}`;
      }
      request = req.clone({ setHeaders: headersConfig });
    } else if (req.url.search(environment.api_url) > -1) {
      // if api request set Basic Auth
      const apiKey = this.userService.getClientApiKey() ?? environment.xApiKey;
      headersConfig = {
        ...headersConfig,
        'x-api-key': `${apiKey}`,
        authorization: `Basic ${this.userService.currentUserValue?.user?.getBasic()}`,
      };

      let params = this.setLocaleParam(req);
      request = req.clone({ params, setHeaders: headersConfig });
    }

    if (req.url.includes('/api/v2/transport/import')) {
      seconds = 1;
    }

    if (
      !req.url.includes('api/admin/rail-app/agency/update') &&
      !req.url.includes('api/admin/call-center/user/') &&
      !req.url.includes('private/v2/transport/notifications/chat/list') &&
      !req.url.includes('api/user/auto-login') &&
      !req.url.includes('api/login_check') &&
      interceptorControl &&
      this.urlPeticionEnCurso.some(
        (data) =>
          data.request === this.requestToHash(request) &&
          this.isRecent(data.time, seconds !== 0 ? seconds : this.seconds)
      )
    ) {
      return of();
    }

    if (this.featureFlags.length > 0) {
      let newParams = request.url.includes('?') ? '&' : '?';

      this.featureFlags.forEach((flag, index) => {
        newParams += (index === 0 ? '' : '&') + flag.key + '=' + flag.value;
      });

      dupReq = request.clone({
        url: request.url + newParams,
      });
    }

    if (
      req.url.includes('/info/links/list') &&
      this.routerService.getParamFromUrl('sib')
    ) {
      const section = this.routerService.getParamFromUrl('sib') || '';

      const newURL = new URL(request.url);

      if (section) {
        newURL.searchParams.set('ibcc', section);
      }

      dupReq = request.clone({
        url: newURL.toString(),
      });
    }

    if (
      this.userService.userIsCallCenter() &&
      this.routerService.getParamFromUrl('sib') &&
      (req.url.search(environment.api_url) > -1 ||
        req.url.includes('/info/links/list'))
    ) {
      const section = this.routerService.getParamFromUrl('sib') || '';
      const market = this.routerService.getParamFromUrl('m') || '';
      const lang = this.routerService.getParamFromUrl('lang') || '';
      const iata = this.routerService.getParamFromUrl('iata') || '';

      const newURL = new URL(request.url);

      if (section) {
        newURL.searchParams.set('ibcc', section);
      }
      if (market) {
        newURL.searchParams.set('market_code', market);
      }

      if (lang) {
        newURL.searchParams.set('client_lang', lang);
      }
      if (iata && iata !== '') {
        newURL.searchParams.set('iata', iata);
      }

      dupReq = request.clone({
        url: newURL.toString(),
      });
    }
    if (!req.url.includes('assets/images')) {
      this.updateUlrsList(dupReq || request, this.seconds);
    }

    if (
      req.url.includes('private/v2/transport/bookings/list') &&
      interceptorControl &&
      this.urlPeticionEnCurso.some(
        (data) =>
          data.url.includes('private/v2/transport/bookings/list') &&
          data.request !== this.requestToHash(req)
      )
    ) {
      // Cancela las solicitudes activas de la misma URL
      return this.cancelPreviousRequests(dupReq || request);
    }

    return this.handleRequest(dupReq || request, next, errorControl);
  }

  private cancelPreviousRequests(req: any): any {
    this.cancelSubject.next();
    return this.httpBackend.handle(req).pipe(takeUntil(this.cancelSubject));
  }

  updateUlrsList(req: HttpRequest<any>, seconds: number) {
    this.urlPeticionEnCurso = this.urlPeticionEnCurso.filter(
      (data) => data.time < seconds
    );
    this.urlPeticionEnCurso.push({
      request: this.requestToHash(req),
      time: new Date(),
      url: req.url,
    });
  }

  requestToHash(req: HttpRequest<any>): string {
    return (
      (req.body ? this.storageService.utf8_to_b64(req.body) : '') +
      (req.url ? this.storageService.utf8_to_b64(req.url) : '') +
      (req.method ? this.storageService.utf8_to_b64(req.method) : '') +
      (req.urlWithParams
        ? this.storageService.utf8_to_b64(req.urlWithParams)
        : '')
    );
  }

  isRecent(time: Date, segundos: number): boolean {
    const tiempo1 = time;
    const tiempo2 = new Date();
    const dif = tiempo2.getTime() - tiempo1.getTime();

    const Segundos_de_T1_a_T2 = dif / 1000;
    const Segundos_entre_fechas = Math.abs(Segundos_de_T1_a_T2);
    return Segundos_entre_fechas < segundos;
  }

  private setLocaleParam(req: HttpRequest<any>) {
    let params = req.params;
    if (params.has('locale')) {
      params = params.delete('locale');
    }
    params = params.append('locale', this.languageService.getLocale());
    return params;
  }

  private openWrongTrainHoursModal(): void {
    const title = 'incompatible_schedule_';
    const message = 'incompatible_schedule_message_';
    const icon = this.managementModal.iconType.info;
    const modalButtons: ModalButtons[] = [
      {
        text: 'modify schedule_',
        class: 'btn-primary',
        emitOnClick: 'cancel',
      },
    ];
    this.managementModal.showCustomModal(
      title,
      message,
      modalButtons,
      icon,
      null,
      { id: 'openWrongTrainHoursModal', isOpen: false }
    );
    this.modalButton$ = this.managementModal
      .getConfirmButton$()
      .pipe(
        filter((res) => res.id === 'openWrongTrainHoursModal'),
        take(1)
      )
      .subscribe(() => this.location.back());
  }

  private handleRequest(request, next, showError: boolean) {
    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        let modalButtons: ModalButtons[] = [
          {
            text: 'close_',
            class: 'btn-primary',
            emitOnClick: 'submit',
          },
        ];
        if (
          request.url !==
          environment.api_backend_url + environment.backend_error_logs
        ) {
          this.errorsService.registerError(
            err,
            this.storageService.getItem('token')!
          );
        }
        if (
          (request.url.includes('/api/v2/transport/import') &&
            (err.status === 403 || err.status === 400)) ||
          request.url.includes('api/profile/v2/company/sync-source')
        ) {
          return throwError(err);
        }
        if (
          request.url?.includes('/api/user/auto-login') &&
          err.status === 410
        ) {
          return throwError(err);
        }

        if (err.url?.includes('admin/timelimit/')) {
          return throwError(err);
        }

        if (err.url?.includes('transport/import-history/')) {
          return throwError(err);
        }
        if (
          err.url?.includes('api/admin/agents') &&
          err.hasOwnProperty('error') &&
          err?.message
        ) {
          return throwError(err);
        }

        if (
          (showError && err.status !== 404) ||
          (err.hasOwnProperty('error') &&
            err?.error?.error?.code &&
            err?.error?.error?.code !== 429 &&
            err?.error?.error?.code !== 4065)
        ) {
          if (
            err.hasOwnProperty('error') &&
            err.error?.error?.message &&
            err.error?.error?.message !== ''
          ) {
            let title = '';
            let message = '';
            let icon: ModalIcon;

            if (err.url?.includes('v2/transport/pricing')) {
              title = 'oops_error_';
              message = err?.error?.error?.message;
              icon = this.managementModal.iconType.generalError;
              modalButtons = [
                {
                  text: 'back to results_',
                  class: 'btn-primary',
                  emitOnClick: 'submit',
                },
              ];
            } else {
              title = err.status === 500 ? 'a problem has occurred_' : '';

              message =
                err.status === 500
                  ? 'and we are going to solve it!_'
                  : err?.error?.error?.message;

              icon =
                err.status === 500
                  ? this.managementModal.iconType.serverError
                  : this.managementModal.iconType.generalError;
            }

            this.managementModal.showCustomModal(
              title,
              message,
              modalButtons,
              icon,
              null,
              { id: 'handleRequest', isOpen: false }
            );
            this.managementModal
              .getConfirmButton$()
              .pipe(
                filter((res) => res.id === 'handleRequest'),
                take(1)
              )
              .subscribe(() => {
                const urlsToCheck: string[] = [
                  'v2/transport/pricing',
                  'v2/transport/quotes',
                ];

                if (urlsToCheck.some((url: string) => err.url?.includes(url))) {
                  this.location.back();
                }
              });
          } else {
            this.managementModal.showCustomModal(
              'an error has occurred_',
              'do you want to go back to the start?_',
              modalButtons,
              this.managementModal.iconType.generalError,
              null,
              { id: 'handleRequest', isOpen: false }
            );
            this.managementModal
              .getConfirmButton$()
              .pipe(
                filter((res) => res.id === 'handleRequest'),
                filter((res) => res.response === 'submit'),
                take(1)
              )
              .subscribe(() => this.redirectToHome());
          }
        }
        return throwError(err);
      })
    );
  }

  redirectToHome() {
    this.routerService.navigateToHome();
  }
}
