import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { PrimeTemplate } from 'primeng/api';
import { MultiSelectModule } from 'primeng/multiselect';
import { NgClass } from '@angular/common';

@Component({
  selector: 'app-multiselect',
  templateUrl: './multiselect.component.html',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    NgClass,
    MultiSelectModule,
    PrimeTemplate,
    TranslateModule,
  ],
})
export class MultiselectComponent implements OnInit, OnDestroy {
  @Input() parentForm: FormGroup;
  @Input() fCName: string;
  @Input() options: any = {};
  sub: any;
  numberChecked = 0;
  show = false;

  ngOnInit() {
    // Init
    if (this.parentForm.get(this.fCName)) {
      this.sub = this.parentForm
        .get(this.fCName)!
        .valueChanges.subscribe((e) => {
          if (e) {
            this.numberChecked = e.length;
          } else {
            this.numberChecked = 0;
            this.show = false;
          }
        });
    }
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  panelHide() {
    if (this.numberChecked > 0) {
      this.show = true;
    } else {
      this.show = false;
    }
  }
  positionInputMultiselect() {
    (
      document.getElementsByClassName('p-multiselect-panel')[0] as HTMLElement
    ).style.top = '31px';
  }
}
