<ng-container [formGroup]="parentForm">
  <input
    type="{{ type ? type : 'text' }}"
    placeholder="{{ options?.placeholder | translate }}"
    [ngClass]="{
      disabled: parentForm.get(fCName)?.status?.toUpperCase() === 'DISABLED'
    }"
    [class]="options?.class"
    [id]="options.id ? options.id : fCName"
    [formControlName]="fCName"
    [attr.data-decidir]="options?.dataDecidir"
    #icon
    OnlyNumber="{{ options?.onlyNumber }}"
    [OnlyNumberInteger]="options?.onlyNumberInteger"
    [mask]="options.mask"
    [prefix]="options.prefix || ''"
    [readonly]="options.readonly"
    (keydown)="enforceNumericInput($event)"
    (input)="enforceCleanInvalidInput($event)"
    (click)="click()"
    [maxlength]="options.maxlength"
    [attr.role]="fCName === 'login' || fCName === 'password' ? '' : 'combobox'"
    [attr.data-cy]="options.id ? options.id : fCName"
    aria-autocomplete="both"
    aria-haspopup="false"
    autocapitalize="off"
    autocomplete="{{ options?.autocomplete ?? 'on' }}"
    autocorrect="off"
    spellcheck="false"
  />
  <span class="porcent-over" *ngIf="porcentOver">%</span>
  <span class="currency" *ngIf="currency">{{ currency }}</span>
  <div *ngIf="options.showLoading" class="showLoading"></div>
</ng-container>
<app-errors-fields
  [field]="icon"
  [errors]="parentForm.controls[fCName]?.errors"
  [show]="options.showErrors"
  [messageError]="options.errorMessage"
>
</app-errors-fields>
