<ng-container [formGroup]="parentForm">
  <div [class]="fCName" [ngClass]="{ noLabel: options.fixLabel }">
    <label
      [ngClass]="{ active: active }"
      class="otherLabel"
      [id]="options.id + 'Label'"
      *ngIf="options.fixLabel"
      >{{ options.placeholder | translate }}
    </label>
    <p-dropdown
      (onShow)="show()"
      (onHide)="hide()"
      [options]="data"
      [class]="options.class"
      [formControlName]="fCName"
      [filter]="options?.filter ?? false"
      filterPlaceholder="{{ options?.filterPlaceholder | translate }}"
      [filterBy]="options.filterBy ?? null"
      [id]="options.id"
      [virtualScroll]="options.phone"
      [virtualScrollItemSize]="31"
      placeholder="{{ options.placeholder | translate }}"
      [ngClass]="{
        error:
          options.showErrors &&
          parentForm.get(fCName)?.errors &&
          !parentForm.get(fCName)?.value,
        open:
          active &&
          (options.class === 'market' ||
            options.class === 'callCenterLanguage'),
        inactive: parentForm.get(fCName)?.pristine,
        selectStyle:
          selectStylePlaceholder.includes(options?.placeholder ?? '') && !parentForm.get(fCName)?.value,
        disabledForm: parentForm.get(fCName)?.disabled
      }"
      [required]="parentForm.get(fCName)?.hasError('required')"
      [attr.data-cy]="fCName"
      optionDisabled="'select_'"
    >
      <ng-template pTemplate="item" let-item *ngIf="options.suffixDate">
        <div class="flex align-items-center suffix-box">
          <span>{{ item?.label }}</span>
          <span class="suffix">{{ item?.suffix }}</span>
        </div>
      </ng-template>
      <ng-template pTemplate="selectedItem" let-item *ngIf="options.suffixDate">
        <div class="suffix-box">
          <span>{{ item?.label }}</span>
          <span class="suffix">{{ item?.suffix }}</span>
        </div>
      </ng-template>

      <ng-template
        pTemplate="item"
        let-item
        *ngIf="options.noTranslate && !options.suffixDate"
      >
        {{ options.phone ? item.name[locale] + ' +' + item.value : item.label }}
      </ng-template>
      <ng-template
        pTemplate="item"
        let-item
        *ngIf="!options.noTranslate && !options.suffixDate"
      >
        <ng-container *ngIf="!options.isFlagSelect; else flag">
          <svg-icon
            *ngIf="item?.icon"
            [src]="'assets/images/icons/' + item.icon + '.svg'"
          ></svg-icon>
        </ng-container>
        <ng-template #flag>
          <img
            *ngIf="item.flag.toLowerCase() !== 'ccpo'"
            src="https://primefaces.org/cdn/primeng/images/demo/flag/flag_placeholder.png"
            [class]="'flag flag-' + item.flag.toLowerCase()"
            style="width: 18px; height: 12px"
            alt="flag"
          />
          <svg-icon
            *ngIf="item.flag.toLowerCase() === 'ccpo'"
            [src]="'assets/images/icons/' + item.flag.toLowerCase() + '.svg'"
          ></svg-icon>
        </ng-template>

        <p [pTooltip]="item?.tooltip | translate">
          {{ getItem(item, options) | translate }}
          <ng-container *ngIf="item.suffix">
            <span>{{ item.suffix }}</span>
          </ng-container>
        </p>
      </ng-template>
      <ng-template
        pTemplate="selectedItem"
        let-item
        *ngIf="options.noTranslate && !options.suffixDate"
      >
        {{ options.phone ? item.label + ' +' + item.value : item.label }}
      </ng-template>
      <ng-template
        pTemplate="selectedItem"
        let-item
        *ngIf="!options.noTranslate && !options.suffixDate"
      >
        <ng-container *ngIf="!options.isFlagSelect; else flag">
          <svg-icon
            *ngIf="item?.icon"
            [src]="'assets/images/icons/' + item.icon + '.svg'"
          ></svg-icon>
        </ng-container>
        <ng-template #flag>
          <img
            *ngIf="item.flag.toLowerCase() !== 'ccpo'"
            src="https://primefaces.org/cdn/primeng/images/demo/flag/flag_placeholder.png"
            [class]="'flag flag-' + item.flag.toLowerCase()"
            style="width: 18px; height: 12px"
            alt="flag"
          />
          <svg-icon
            *ngIf="item.flag.toLowerCase() === 'ccpo'"
            [src]="'assets/images/icons/' + item.flag.toLowerCase() + '.svg'"
          ></svg-icon>
        </ng-template>
        {{
          (options.phone ? item?.label + ' +' + item?.value : item?.label)
            | translate
        }}
      </ng-template>
    </p-dropdown>
  </div>
</ng-container>
<app-errors-fields
  class="select"
  [errors]="parentForm.controls[fCName].errors"
  [show]="options.showErrors"
>
</app-errors-fields>
