import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class VoucherBookingService {
  compareRepeatedFields(parameters: any[], parametersToCheck: any[]): void {
    parametersToCheck.forEach((parametersToCheckItem) => {
      parametersToCheckItem.fields.forEach((field: any) => {
        // Verificar si el campo ya existe en el primer objeto
        const contextExistente = parameters.find(
          (obj) =>
            obj.context === parametersToCheckItem.context &&
            obj.type === parametersToCheckItem.type
        );
        if (contextExistente) {
          // Verificar si el campo no existe ya en el primer objeto
          if (
            !contextExistente.fields.some(
              (existingField: any) =>
                existingField.name === field.name &&
                (existingField.mandatory === field.mandatory ||
                  (existingField.mandatory !== false &&
                    field.mandatory !== true) ||
                  (existingField.mandatory !== true &&
                    field.mandatory !== false))
            )
          ) {
            // Añadir el campo al primer objeto
            contextExistente.fields.push(field);
          }
        } else {
          // Si no existe un objeto con el mismo contexto y tipo, añadirlo al principio
          parameters.unshift(parametersToCheckItem);
        }
      });
    });
    // comparo los fields de los repetidos
    for (const parameter of parameters) {
      const parameterToCheck = parametersToCheck.find(
        (paramToCheck: any) => paramToCheck.type === parameter.type
      );

      if (!parameterToCheck) {
        return;
      }

      for (const field of parameter.fields) {
        const fieldToCheck = parameterToCheck.fields.find(
          (paramField: any) => paramField.name === field.name
        );

        if (!fieldToCheck) {
          return;
        }

        if (fieldToCheck.mandatory) {
          // si el repetido es mandatory modifico el actual
          field.mandatory = true;
        } else if (parameterToCheck?.pax_refs) {
          // comparo los pax, si alguno no está incluido lo incluyo
          for (const pax of parameterToCheck.pax_refs) {
            if (!field.pax_refs.includes(pax)) {
              field.pax_refs.push(pax);
            }
          }
        } else if (field?.enum && parameterToCheck?.enum) {
          // comparo los docs, si falta alguno lo incluyo
          for (const type of parameterToCheck.enum) {
            if (!field.enum.includes(type)) {
              field.enum.push(type);
            }
          }
        }
      }
    }
  }

  getConvertedSeatMaps(seatMaps: any[]): any[] {
    const convertedSeatMaps: any[] = [];

    seatMaps?.forEach((seatMap: any) => {
      if (!convertedSeatMaps.find((sm: any) => sm.car === seatMap.car)) {
        convertedSeatMaps.push({
          avail: { floors: {} },
          car: seatMap.car,
          columns: {},
          characteristics: {},
          exceptions: {},
          first_row: {},
          last_row: {},
        });
      }

      const seatMapIndex = convertedSeatMaps.findIndex(
        (sm: any) => sm.car === seatMap.car
      );

      if (seatMap.floor !== null && seatMap.floor !== undefined) {
        const keysToUpdate = [
          'columns',
          'characteristics',
          'exceptions',
          'first_row',
          'last_row',
        ];

        convertedSeatMaps[seatMapIndex].avail.floors = {
          ...convertedSeatMaps[seatMapIndex].avail.floors,
          [seatMap.floor]: seatMap.avail,
        };

        keysToUpdate.forEach((key) => {
          convertedSeatMaps[seatMapIndex][key] = {
            ...convertedSeatMaps[seatMapIndex][key],
            [seatMap.floor]: seatMap[key],
          };
        });
      } else {
        convertedSeatMaps[seatMapIndex].avail = seatMap.avail;
      }
    });

    return convertedSeatMaps;
  }
}
