import { Injectable } from '@angular/core';
import { TokenStatusService } from '../token/tokenStatus.service';
import { BehaviorSubject } from 'rxjs';
import { UserDataModel } from '../../../shared/models/user-data.model';

@Injectable({ providedIn: 'root' })
export class UserStateService {
  private currentUser: any = null;
  currentUserSubject = new BehaviorSubject<UserDataModel>(null!);

  constructor(private tokenService: TokenStatusService) {}

  getCurrentUser(): any {
    return this.currentUser;
  }

  setCurrentUser(user: any): void {
    this.currentUser = user;
  }

  setCurrentUserSubject(user: any): void {
    this.currentUserSubject = user;
  }

  userIsCallCenter(): boolean {
    const user = this.getCurrentUser();

    if (!user) {
      return false;
    }

    return user?.client?.cc || user?.c?.cc;
  }

  isRailAPP(): boolean {
    const user = this.getCurrentUser();

    if (!user) {
      return false;
    }

    return user?.client?.hasOwnProperty('ra');
  }

  isIberia(): boolean {
    const user = this.getCurrentUser();

    if (!user) {
      return false;
    }

    return user?.client?.tn === 'iberia';
  }

  isWelcomeFLy(): boolean {
    const user = this.getCurrentUser();

    if (!user) {
      return false;
    }

    return user?.client?.tn === 'vistatravel' && user.client?.ci === 4;
  }

  userIsLleegoInternal(): boolean {
    const user = this.getCurrentUser();

    if (!user) {
      return false;
    }

    const decodedToken = this.tokenService.decodeToken(
      localStorage.getItem('token') ?? ''
    );

    return decodedToken.roles?.includes('ROLE_LLEEGO_INTERNAL');
  }

  userIsSuperConsolidator(): boolean {
    const user = this.getCurrentUser();

    if (!user) {
      return false;
    }

    return user?.user?.r === 'RSCA';
  }

  userIsConsolidator(): boolean {
    const user = this.getCurrentUser();

    if (!user) {
      return false;
    }

    return (
      user?.user?.r === 'RCA' ||
      user?.user?.r === 'RCAL' ||
      user?.user?.r === 'RSCA' ||
      user?.user?.r === 'RC'
    );
  }

  userIsFacturacion(): boolean {
    const user = this.getCurrentUser();

    if (!user) {
      return false;
    }

    return user?.user?.r === 'RF';
  }

  getUserURLHome(): string {
    const user = this.getCurrentUser();

    if (!user) {
      return '';
    }

    return user?.user?.he;
  }

  haveFrogedChatActive(): boolean {
    const user = this.getCurrentUser();

    if (!user) {
      return false;
    }

    return user?.s?.cfai && user?.s?.cfai !== '';
  }

  destroyUser(): void {
    this.currentUserSubject.next(null!);
  }
}
