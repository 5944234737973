import {
  Component,
  OnInit,
  Input,
  ViewChild,
  Output,
  ElementRef,
  OnDestroy,
  EventEmitter, forwardRef,
} from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MapsService } from '../../../../../../core/services/maps/maps.service';
import { TranslateModule } from '@ngx-translate/core';
import { FieldsCollectionComponent } from '../fields-collection.component';
import { DropdownComponent } from '../../../dropdown/dropdown.component';
import { SvgIconComponent } from 'angular-svg-icon';
import { ErrorsFieldsComponent } from '../errors-fields/errors-fields.component';
import { NgClass, NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'app-input-autocomplete',
    templateUrl: './input-autocomplete.component.html',
    styleUrls: [
        '../input/input.component.scss',
        './input-autocomplete.component.scss',
    ],
    standalone: true,
    imports: [
        ReactiveFormsModule,
        NgClass,
        NgIf,
        forwardRef(() => ErrorsFieldsComponent),
        forwardRef(() => FieldsCollectionComponent),
        SvgIconComponent,
        DropdownComponent,
        NgFor,
        TranslateModule,
    ],
})
export class InputAutocompleteComponent implements OnInit, OnDestroy {
  @Input() parentForm: FormGroup;
  @Input() fCName: string;
  @Input() type;
  @Input() options: any = {};
  @Input() map = false;
  @Output() onFocus = new EventEmitter<boolean>(false);
  @ViewChild('element', { static: true }) input: ElementRef;
  selectDropdown = 0;
  show = false;
  focus = false;
  sub: any;
  showMap = false;
  elements: any = [];
  elementHover = false;
  @Input() set data(e) {
    if (e && (this.focus || e[0]?.showError)) {
      this.elements = e;
      this.show = true;
    } else {
      this.show = false;
    }
  }

  checkMap = [
    {
      type: 'checkbox',
      class: '',
      name: 'map',
      options: {
        class: 'form-input white',
        placeholder: 'show on map_',
        label: false,
      },
    },
  ];

  value = '';

  dropdownOptions = {
    class: 'dropdown',
  };

  constructor(private mapsService: MapsService) {}

  ngOnInit() {
    if (this.parentForm.get(this.fCName)?.value) {
      this.input.nativeElement.value = this.parentForm.get(
        this.fCName
      )?.value.select;
    }

    this.sub = this.parentForm.get(this.fCName)?.valueChanges.subscribe((e) => {
      if ((e && e.select) || e?.select === '') {
        this.input.nativeElement.value = e.select;
      } else if (!e) {
        this.input.nativeElement.value = '';
      }
    });
  }

  emitShowMap() {
    this.mapsService.dropOffValue.next(this.parentForm.get('type')?.value);
    this.mapsService.show.next(true);
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  onKeyUp(e) {
    if (
      !(e.keyCode >= 37 && e.keyCode <= 40) &&
      e.keyCode !== 13 &&
      e.keyCode !== 9
    ) {
      this.parentForm.get(this.fCName)?.setValue(this.input.nativeElement.value);
      this.selectDropdown = 0;
    }
  }

  click() {
    this.focus = true;
    this.onFocus.emit(this.focus);
    this.input.nativeElement.select();
  }

  focusIn() {
    if (this.map) {
      this.showMap = true;
    }
    this.focus = true;
    this.onFocus.emit(this.focus);
  }

  focusOut() {
    setTimeout(() => {
      if (this.map) {
        this.showMap = false;
      }
    }, 150);
    this.focus = false;
    this.onFocus.emit(this.focus);
    if (
      this.parentForm.get(this.fCName)?.value &&
      !this.parentForm.get(this.fCName)?.value.select
    ) {
      this.parentForm.get(this.fCName)?.patchValue('');
    }
  }

  onKeyDown(e) {
    if (this.map) {
      this.showMap = false;
    }
    if (this.elements[this.selectDropdown]) {
      if (e.code === 'ArrowDown') {
        this.show = true;
        if (!this.elements[this.selectDropdown + 1]) {
          return false;
        }
        this.selectDropdown++;
        this.input.nativeElement.value =
          this.elements[this.selectDropdown].select;
      } else if (e.code === 'ArrowUp') {
        if (!this.elements[this.selectDropdown - 1]) {
          return false;
        }
        this.selectDropdown--;
        this.input.nativeElement.value =
          this.elements[this.selectDropdown].select;

      } else if (e.code === 'Enter') {
        this.elements[this.selectDropdown].enter = true;
        this.selectAirport(this.elements[this.selectDropdown], true);
      } else if (e.code === 'Tab') {
        if (this.elements[this.selectDropdown]) {
          this.elements[this.selectDropdown].tab = true;
          this.selectAirport(this.elements[this.selectDropdown], true);
        }
      }
    }
    return undefined;
  }

  selectAirport(airport, noClose, optionClick?) {
    if (optionClick) {
      airport.click = true;
    }

    if (airport) {
      this.parentForm.controls[this.fCName].setValue(airport);
      if (airport?.nearAirports) {
        if (this.fCName === 'origin') {
          this.parentForm.get('nearAirports')?.setValue(true);
        } else if (this.fCName === 'destination') {
          this.parentForm.get('nearAirportsDest')?.setValue(true);
        }
      } else if (
        this.parentForm.get('nearAirports') &&
        this.parentForm.get('nearAirportsDest')
      ) {
        if (this.fCName === 'origin') {
          this.parentForm.get('nearAirports')?.setValue(false);
        } else if (this.fCName === 'destination') {
          this.parentForm.get('nearAirportsDest')?.setValue(false);
        }
      }
    }
  }
}
