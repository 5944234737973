import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[OnlyNumberInteger]',
  standalone: true,
})
export class OnlyNumberInteger {
  @Input() OnlyNumberInteger: boolean = false;

  constructor(private el: ElementRef) {}

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent): void {
    if (!this.OnlyNumberInteger) {
      return;
    }

    const allowedKeys = [
      'Backspace', 'Tab', 'Enter', 'Escape', 'ArrowLeft', 'ArrowRight', 'Delete'
    ];

    if (event.ctrlKey && ['a', 'c', 'x', 'v'].includes(event.key.toLowerCase())) {
      return;
    }

    if (!allowedKeys.includes(event.key) && !/^[0-9]$/.test(event.key)) {
      event.preventDefault();
    }
  }
}
