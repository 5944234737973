<ng-container [formGroup]="parentForm">
  <div
    [tabindex]="parentForm.get(fCName)?.enabled ? 0 : -1"
    class="input-calendar-container"
    [id]="options.id || fCName"
    [ngClass]="{
      disabled: parentForm.get(fCName)?.disabled,
      open: options.show
    }"
    #icon
    data-cy="calendar-over"
  >
    <svg-icon src="assets/images/icons/icon calendar.svg"> </svg-icon>
    <span [ngClass]="options?.class">
      {{
        (parentForm.get(fCName)?.value
          ? parentForm.get(fCName)?.value
          : options.initialDay
        ) | formatDate : 'inputCalendar'
      }}
    </span>
  </div>
  <app-dropdown
    [disabled]="parentForm.get(fCName)?.disabled"
    [show]="options.show"
    (showChange)="emitShow($event)"
    [ref]="icon"
    #dropdown
    [options]="dropdownOptions"
  >
    <div class="calendarContent">
      <p-calendar
        *ngIf="showCalendar"
        [inline]="true"
        [minDate]="mDate"
        [showOtherMonths]="false"
        [firstDayOfWeek]="1"
        (onSelect)="onSelect($event)"
        [formControlName]="fCName"
        (onMonthChange)="monthChange($event); changeMonth()"
        [numberOfMonths]="numberOfMonths"
        dateFormat="dd-MMM-yyyy"
      >
        <ng-template pTemplate="date" let-date>
          <span
            [ngClass]="{
              backSelect: isBackSelect(date),
              between:
                this.parentForm.get('finalDay') && !onlyOne
                  ? (date.month + 1 + '-' + date.day + '-' + date.year
                    | betweenDates
                      : parentForm.get('initialDay').value
                      : this.parentForm.get('finalDay').value)
                  : false
            }"
            data-cy="calendar"
          >
            {{ date.day }}
          </span>
        </ng-template>
      </p-calendar>
      <!-- <div class="timepickerContainer" *ngIf="options.time">
        <div class="headerTimepicker">
          <img src="assets/images/icons/icon reloj.svg" alt="reloj" />
          <p>{{ 'departure time_' | translate | uppercase }}</p>
        </div>
        <div class="calendarSlider">
          <ngx-slider
            [(value)]="minValueDeparture"
            [(highValue)]="maxValueDeparture"
            [options]="optionsSliderDeparture"
            (valueChange)="changeDepartureHours()"
          >
          </ngx-slider>
        </div>
        <div class="options">
          <p
            *ngFor="let option of optionsRange"
            (click)="changeDeparture(option.option)"
            [ngClass]="{ primary: optionSelectedDeparture === option.option }"
            [ngStyle]="{
              'font-weight':
                optionSelectedDeparture === option.option ? '500' : 'normal'
            }"
          >
            {{ option.title | translate }}
          </p>
        </div>
        <div class="headerTimepicker">
          <img src="assets/images/icons/icon reloj.svg" alt="reloj" />
          <p>{{ 'arrival time_' | translate | uppercase }}</p>
        </div>
        <div class="calendarSlider">
          <ngx-slider
            [(value)]="minValueArrival"
            [(highValue)]="maxValueArrival"
            [options]="optionsSliderArrival"
            (valueChange)="changeArrivalHours()"
          >
          </ngx-slider>
        </div>
        <div class="options">
          <p
            *ngFor="let option of optionsRange"
            (click)="changeArrival(option.option)"
            [ngClass]="{ primary: optionSelectedArrival === option.option }"
            [ngStyle]="{
              'font-weight':
                optionSelectedArrival === option.option ? '500' : 'normal'
            }"
          >
            {{ option.title | translate }}
          </p>
        </div>
      </div> -->
    </div>

  </app-dropdown>
</ng-container>
