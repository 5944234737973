import { Injectable } from '@angular/core';
import { DomSanitizer, Title } from '@angular/platform-browser';
import {
  ActivatedRoute,
  NavigationEnd,
  NavigationStart,
  Router,
} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { PrimeNGConfig } from 'primeng/api';
import { filter, map } from 'rxjs/operators';
import { LanguageService } from '../language/language.service';
import { routerHome } from 'src/app/core/configurations/configurations';
import { BehaviorSubject } from 'rxjs';
import { UserStateService } from '../user/userState.service';
import { TokenStatusService } from '../token/tokenStatus.service';
import { AuthenticationStatusService } from '../../authentication/authenticationStatus.service';

@Injectable({
  providedIn: 'root',
})
export class RouterService {
  private routerNavigateBack: string;
  corporate$ = new BehaviorSubject(false);
  type$ = new BehaviorSubject(false);

  constructor(
    public languageService: LanguageService,
    public sanitizer: DomSanitizer,
    private titleService: Title,
    private activatedRoute: ActivatedRoute,
    private translateService: TranslateService,
    private primengConfig: PrimeNGConfig,
    private authStatus: AuthenticationStatusService,
    private router: Router,
    private userStateService: UserStateService,
    private tokenService: TokenStatusService
  ) {}

  getTitleTab(): void {
    this.primengConfig.ripple = true;
    const appTitle = this.titleService.getTitle();
    this.router.events
      .pipe(
        filter(
          (event): event is NavigationEnd => event instanceof NavigationEnd
        ),
        map(() => {
          let child = this.activatedRoute.firstChild;
          let data = child?.snapshot?.data;
          while (child?.firstChild) {
            child = child?.firstChild;
            data = {
              ...data,
              ...child?.snapshot?.data,
            };
          }
          this.corporate$.next(data?.['corporate']);
          this.type$.next(data?.['type']);
          if (data?.['home']) {
            this.routerNavigateBack = data['home'];
          }
          if (data?.['title']) {
            return child?.snapshot.data;
          }
          return appTitle;
        })
      )
      .subscribe((data: any) => {
        if (data?.['title']) {
          const title = this.translateService.instant(data['title']);
          this.titleService.setTitle(title);
          if (data['loadingDots']) {
            let dots = '';
            const interval: ReturnType<typeof setInterval> = setInterval(() => {
              if (this.titleService.getTitle().includes(title)) {
                if (dots.length < 3) {
                  dots += '.';
                } else {
                  dots = '';
                }
                this.titleService.setTitle(`${title} ${dots}`);
              } else {
                clearInterval(interval);
              }
            }, 500);
          }
        }
      });
  }

  controlIBCCParametersToURLAPIStart(): void {
    let parameters = {};
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationStart),
        filter((_) => this.userStateService.userIsCallCenter())
      )
      .subscribe((res) => {
        parameters = this.agregarParametroToIBCC(res);
      });

    this.router.events
      .pipe(
        filter(
          (event): event is NavigationEnd => event instanceof NavigationEnd
        ),
        filter((_) => this.userStateService.userIsCallCenter())
      )
      .subscribe((res) => {
        if (this.hasDifferentParameter(parameters, res)) {
          this.addGetParamsToUrl(parameters);
        }
      });
  }

  getUrlWithExtraParams(url: string): string {
    let newURL: URL;

    if (url.startsWith('http')) {
      newURL = new URL(url);
    } else {
      const currentUrl = new URL(window.location.href);
      newURL = new URL(url, currentUrl.origin);
    }

    if (this.userStateService.userIsCallCenter()) {
      const parameters = this.agregarParametroToIBCC(this.router);

      for (let key in parameters) {
        newURL.searchParams.set(key, parameters[key]);
      }
    }

    return newURL.toString();
  }

  getParamFromUrl(paramName: string): string | null {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams?.get(paramName) ?? null;
  }

  addGetParamsToUrl(params: any): void {
    if (!params || typeof params !== 'object') {
      return;
    }

    const url = new URL(window.location.href);
    const searchParams = url.searchParams;

    for (const key in params) {
      searchParams.set(key, params[key]);
    }

    window.history.replaceState({}, '', url.toString());
    history.pushState({}, '', url.toString());
  }

  agregarParametroToIBCC(value: any): any {
    const clientStaticData = JSON.parse(
      this.tokenService.getItem('client_static_data') ?? ''
    );
    const urlToSection = value.url.includes('/call-center/b2b')
      ? 'b2b'
      : value.url.includes('/call-center/b2c')
        ? 'b2c'
        : '';
    const market = this.controlsValueIBCCParams(
      'm',
      clientStaticData?.markets[0]?.code
    );
    const lang = this.controlsValueIBCCParams(
      'lang',
      clientStaticData?.languages[0]?.language
    );

    return {
      lang,
      m: market,
      sib: urlToSection,
    };
  }

  hasDifferentParameter(parameters: any, route: any): boolean {
    return (
      route.url !== this.router.url ||
      Object.keys(parameters).some(
        (paramKey) =>
          this.activatedRoute.snapshot.queryParamMap.get(paramKey) !==
          parameters[paramKey]
      )
    );
  }

  controlsValueIBCCParams(valueKeyParam: any, valueDefect: any): any {
    return this.getParamFromUrl(valueKeyParam)
      ? this.getParamFromUrl(valueKeyParam)
      : valueDefect;
  }

  navigateToHome(isInitLogin = false): void {
    const user = this.userStateService.getCurrentUser();

    if (
      this.userStateService.userIsLleegoInternal() &&
      !this.router.routerState.snapshot.url.includes('internal-user')
    ) {
      this.router.navigate(['/user-search']).then();
    } else if (
      user &&
      (user.u?.r === 'RCCA' || user.u?.cc_l === 'Administrador')
    ) {
      this.router.navigate(['admin-call-center/user/list']).then();
    } else if (
      user &&
      (user.u?.nc === 'iberia_links_b2c' || user.u?.nc === 'iberia_links_b2b')
    ) {
      this.router.navigate(['admin-call-center/info/links']).then();
    } else if (this.userStateService.userIsSuperConsolidator()) {
      this.router.navigate(['super-consolidator/home']).then();
    } else if (this.userStateService.userIsConsolidator()) {
      this.router.navigate(['consolidator/home']).then();
    } else if (this.userStateService.userIsFacturacion()) {
      this.router.navigate(['facturacion']).then();
    } else if (this.userStateService.userIsCallCenter()) {
      const urlBase = user.u.cc_p ?? null;
      if (urlBase) {
        if (urlBase?.toLowerCase() !== 'mix' && !isInitLogin) {
          this.router.navigate(['call-center', urlBase]).then();
        } else {
          this.router.navigate(['call-center']).then();
        }
      } else {
        this.authStatus.logout();
      }
    } else if (
      this.userStateService.getCurrentUser()?.client?.ra &&
      this.userStateService.getCurrentUser().client.ra?.dp
    ) {
      if (!this.userStateService.getCurrentUser().client.ra?.da) {
        this.router.navigate(['rail-app/form']).then();
      } else {
        this.router.navigate(['rail-app']).then();
      }
    } else if (
      this.routerNavigateBack &&
      this.routerNavigateBack !== routerHome.homeFligthRouter &&
      this.routerNavigateBack !== 'call-center' &&
      this.routerNavigateBack !== 'rail-app'
    ) {
      this.router.navigate(['transport', this.routerNavigateBack]).then();
    } else if (user?.getHomePage()) {
      this.router.navigate([user.getHomePage()]).then();
    }
  }

  redirectToNewUrl(url: string, params?: any): void {
    if (!params) {
      params = {};
    }
    this.router
      .navigate([url], {
        queryParams: params,
      })
      .then();
  }
}

export interface routeData {
  type?: string;
  url?: string;
  home?: string;
  source?: string;
  budget?: boolean;
  printView?: boolean;
  [key: string]: any;
}

export interface routeParams {
  flightId?: string;
  id?: string;
  code?: any;
  resident?: any;
  [key: string]: any;
}

export interface routeQueryParams {
  format?: string;
  idEXP?: any;
  idCRM?: any;
  [key: string]: any;
}
