import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { UserService } from '../user/user.service';

@Injectable({
  providedIn: 'root'
})
export class RailappSocket {
  private ws: WebSocket;
  messageSubject: Subject<any> = new Subject();
  private reconnectInterval: number = 100; // Intervalo de reconexión en ms
  private url: string;
  private channel: string;

  /**
   * @param userService Se inyecta para poder utilizar datos del usuario si es necesario.
   * @param channel Identificador del canal (p.ej., "asas123")
   */
  constructor(private userService: UserService) {
  }

  setChannel(channel: string) :void{
    this.channel = channel;
  }

  public close(){
    if(this.ws){
      this.ws.close();
    }
  }

  /**
   * Crea y configura la conexión WebSocket.
   */
  public connect(): void {

    // Usar el esquema wss:// en producción o ws:// para pruebas locales.
    this.url = `wss://socket.lleego.es/ws?ch=railapp${this.channel}`;
    // Si pruebas en local, podrías usar algo como:
    // this.url = `ws://localhost:5556/ws?ch=railapp${channel}`;
    this.ws = new WebSocket(this.url);

    this.ws.addEventListener('message', (event: MessageEvent) => {
      if(!this.userService.isUserConnected()){
        this.ws.close();
        return;
      }
      // Si el mensaje es un Blob, convertirlo a texto
      if (event.data instanceof Blob) {
        const reader = new FileReader();
        reader.onload = () => {
          this.processReceivedMessage(reader.result as string);
        };
        reader.readAsText(event.data);
      } else if (typeof event.data === 'string') {
        this.processReceivedMessage(event.data);
      } else {
        console.error('Tipo de dato desconocido en event.data', event.data);
      }
    });

    this.ws.addEventListener('error', (error) => {
      console.error('Error en WebSocket:', error);
    });

    this.ws.addEventListener('close', () => {
      if(!this.userService.isUserConnected()){
        return;
      }
      console.warn('Conexión WebSocket cerrada, intentando reconectar en', this.reconnectInterval, 'ms', this.channel);
      // No completamos el subject, solo intentamos reconectar
      setTimeout(() => this.connect(), this.reconnectInterval);
    });
  }

  /**
   * Procesa el mensaje recibido convirtiéndolo a objeto JSON
   * y emitiéndolo al subject si no es un eco de un mensaje enviado.
   */
  private processReceivedMessage(data: string): void {
    try {
      const message = JSON.parse(data);
      // Ignorar mensajes que tengan la propiedad sentByClient
      if (message.sentByClient) {
        return;
      }
      this.messageSubject.next(message);
    } catch (error) {
      this.messageSubject.error(error);
    }
  }

  /**
   * Envía un mensaje empaquetado con un nombre de evento y datos.
   * Se añade la propiedad "sentByClient" para evitar procesar el eco.
   * @param eventName Nombre del evento.
   * @param data Datos a enviar.
   */
  emit(eventName: string, data: any): void {
    const payload = JSON.stringify({ event: eventName, data, sentByClient: true });
    if (this.ws.readyState === WebSocket.OPEN) {
      this.ws.send(payload);
    } else {
      this.ws.addEventListener('open', () => {
        this.ws.send(payload);
      });
    }
  }

  /**
   * Retorna un Observable que emite los datos de los mensajes entrantes
   * que coincidan con el nombre de evento solicitado.
   * @param eventName Nombre del evento a filtrar.
   */
  fromEvent(eventName: string): Observable<any> {
    return this.messageSubject.asObservable().pipe(
      filter((message: any) => message.event === eventName),
      map((message: any) => message.data)
    );
  }

  // Nuevo método para enviar mensajes usando railAppSocket
  sendMessageViaRailAppSocket(message: messageSocketRailApp): void {
      this.emit('message', message);
  }
}

interface messageSocketRailApp {
  action: string,
  booking_id?: string,
  booking_locator?: string,
  locator?: string,
}
